import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { HeroSwiper } from "../components/heroSwiper";
import Shell from "../layout/shell";

import hero1 from "../assets/images/hero-placeholder.svg";

import * as styles from "./index.module.scss";
import { CopyBlock } from "../components/copyBlock";
import { SplitBlock } from "../components/splitBlock";
import { Card } from "../components/card";
import { VerticalWrapPanel } from "../components/verticalWrapPanel";
import { GetInTouchPageQuery } from "../generated/graphql";

const GetInTouch: React.FC<PageProps<GetInTouchPageQuery>> = (pageProps) => {
  const data = pageProps.data.prismicGetInTouchPage?.data;
  return (
    <Shell>
      <div className="fixed-width">
        <h1>{data?.title}</h1>
        </div>
        
        <hr />
        <div className="fixed-width">
        <p className="intro">{data?.intro_copy}</p>
        <VerticalWrapPanel maxRows={2} horizontalGap={20} packing={375} verticalGap={50}>
          {data?.contacts?.map((c) => (
            <div>
              <hr />
              <h3>{c?.contact_title}</h3>
              <p>{c?.contact_description}</p>
              <a href={`mailto:${c?.contact_email_address}`}>
                Get in touch
              </a>
            </div>
          ))}
        </VerticalWrapPanel>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query GetInTouchPage {
    prismicGetInTouchPage {
      data {
        title
        intro_copy
        contacts {
          contact_description
          contact_email_address
          contact_title
        }
      }
    }
  }
`;

export default GetInTouch;
